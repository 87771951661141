"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JwtHighbondClient = void 0;
var config_1 = require("./config");
var JwtHighbondClient = /** @class */ (function () {
    function JwtHighbondClient(config) {
        if (config === void 0) { config = {}; }
        this.pendingRefresh = null;
        this.config = __assign(__assign({}, config_1.Config), config);
    }
    JwtHighbondClient.prototype.fetchWithTokenRefresh = function (requestInfo, requestOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var response, redirectUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchWithRetry(requestInfo, requestOptions)];
                    case 1:
                        response = _a.sent();
                        if (!(response.status === 401)) return [3 /*break*/, 5];
                        return [4 /*yield*/, this.refreshToken()];
                    case 2:
                        redirectUrl = _a.sent();
                        if (!redirectUrl) return [3 /*break*/, 3];
                        if (this.config.autoRedirect) {
                            window.location.href = redirectUrl;
                        }
                        return [2 /*return*/, response];
                    case 3: return [4 /*yield*/, this.fetchWithRetry(requestInfo, requestOptions)];
                    case 4: return [2 /*return*/, _a.sent()];
                    case 5: return [2 /*return*/, response];
                }
            });
        });
    };
    JwtHighbondClient.prototype.fetchJson = function (requestInfo, requestOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var opts, response, data, error, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        opts = __assign(__assign({ credentials: 'include' }, requestOptions), { headers: __assign({ Accept: 'application/json' }, requestOptions === null || requestOptions === void 0 ? void 0 : requestOptions.headers) });
                        return [4 /*yield*/, this.fetchWithTokenRefresh(requestInfo, opts)];
                    case 1:
                        response = _b.sent();
                        if (!response.ok) return [3 /*break*/, 3];
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _b.sent();
                        return [2 /*return*/, data];
                    case 3:
                        _a = Error.bind;
                        return [4 /*yield*/, response.text()];
                    case 4:
                        error = new (_a.apply(Error, [void 0, _b.sent()]))();
                        error.name = response.statusText;
                        throw error;
                }
            });
        });
    };
    JwtHighbondClient.prototype.fetchWithRetry = function (requestInfo, requestOptions) {
        return __awaiter(this, void 0, void 0, function () {
            var maxAttempts, attempt, response, _loop_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        maxAttempts = 5;
                        attempt = 1;
                        return [4 /*yield*/, fetch(requestInfo, requestOptions)];
                    case 1:
                        response = _a.sent();
                        _loop_1 = function () {
                            var randomBackoff;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        randomBackoff = Math.random() * (attempt - 1);
                                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, (1 + randomBackoff) * 1000); })];
                                    case 1:
                                        _a.sent();
                                        attempt++;
                                        return [4 /*yield*/, fetch(requestInfo, requestOptions)];
                                    case 2:
                                        response = _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _a.label = 2;
                    case 2:
                        if (!(response.status === 429 && attempt < maxAttempts)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_1()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 2];
                    case 4: return [2 /*return*/, response];
                }
            });
        });
    };
    JwtHighbondClient.prototype.refreshToken = function () {
        var _this = this;
        if (!this.pendingRefresh) {
            this.pendingRefresh = this._refreshToken().finally(function () {
                _this.pendingRefresh = null;
            });
        }
        return this.pendingRefresh;
    };
    JwtHighbondClient.prototype._refreshToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var hostname, subdomain, appSubdomain, region, refreshPromise, refreshSubdomainPromise, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        hostname = this.config.hostname ? this.config.hostname : window.location.hostname;
                        subdomain = hostname === 'localhost' ? hostname : hostname.split('.').reverse()[3];
                        appSubdomain = hostname === 'localhost' ? 'us' : hostname.split('.').reverse()[2];
                        region = this.regionFromAppSubdomain(appSubdomain);
                        refreshPromise = this.putWithCredentials(this.config.tokenRefreshUrl);
                        refreshSubdomainPromise = this.config.noSubdomain
                            ? Promise.resolve()
                            : this.putWithCredentials(this.config.tokenRefreshUrl + "?org_subdomain=" + subdomain + "&region=" + region);
                        refreshSubdomainPromise.catch(function () {
                            // To avoid UnhandledPromiseRejectionWarning if refreshSubdomainPromise rejects before refreshPromise resolves.
                        });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, refreshPromise];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        _a = _c.sent();
                        return [2 /*return*/, this.config.loginUrl + "?redirect_uri=" + window.location.href];
                    case 4:
                        _c.trys.push([4, 6, , 7]);
                        return [4 /*yield*/, refreshSubdomainPromise];
                    case 5:
                        _c.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        _b = _c.sent();
                        return [2 /*return*/, this.config.loginUrl];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    JwtHighbondClient.prototype.putWithCredentials = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetch(url, {
                            method: 'PUT',
                            credentials: 'include',
                        })];
                    case 1:
                        response = _a.sent();
                        if (!response.ok) {
                            throw new Error(response.statusText);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    JwtHighbondClient.prototype.regionFromAppSubdomain = function (appSubdomain) {
        var regionShortcode = appSubdomain.split('-').reverse()[0];
        // map region codes for urls to the region the org belongs to in launchpad
        // usg maps to us for instance, as does the case where appSubdomain has no -regionCode
        return ['eu', 'ap', 'au', 'ca', 'af', 'sa'].includes(regionShortcode) ? regionShortcode : 'us';
    };
    return JwtHighbondClient;
}());
exports.JwtHighbondClient = JwtHighbondClient;
