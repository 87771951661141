import { fetchJson } from 'http_client';

export default class LocalizationService {
  async getTranslations(locale: string): Promise<Record<string, string>> {
    const fallback = {};

    try {
      const rawTranslations = await fetchJson(`/localization/${locale}.json`);
      const entries = rawTranslations.map(({ key, value }) => [key, value]);
      return Object.fromEntries(entries);
    } catch (error) {
      return fallback;
    }
  }
}
