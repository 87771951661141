"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Do not edit this file, it is automatically generated by @paprika/build-translations
var locales = {
    "es": {
        "translation": {
            "global_navigation": {
                "app_switcher_title": "Diligent HighBond",
                "navigation_items": {
                    "logo_org_name": "Ir a Launchpad",
                    "logo": "Volver a Launchpad",
                    "app_switcher": "Cambiar aplicaciones",
                    "announcer": "Anuncios",
                    "helper": "Ayuda y soporte",
                    "user": "Inició sesión: {{username}}",
                    "user_with_customer": "{{username}} {{customerName}}",
                    "sign_in": "Iniciar sesión",
                    "keyboard_shortcut_panel_tooltip": "Método abreviado del teclado [Ctrl + /]"
                },
                "apps": {
                    "academy": "Academia",
                    "community": "Comunidad",
                    "projects": "Proyectos",
                    "results": "Resultados",
                    "storyboards": "Storyboards",
                    "mission_control": "Control de la misión",
                    "risks": "Estrategia",
                    "reports": "Reportes",
                    "launchpad": "Launchpad",
                    "robots": "Robots",
                    "frameworks": "Marcos",
                    "compliance_maps": "Mapas de cumplimiento",
                    "assurance_plans": "Planes de aseguramiento",
                    "project_scheduler": "Programador",
                    "issue_tracker": "Rastreador de asuntos",
                    "task_tracker": "Rastreador de tareas",
                    "project_timesheets": "Planillas de horas",
                    "dashboard": "Tablero de mando de {{module}}",
                    "master_library": "Configuración"
                },
                "external": {
                    "diligent": "Diligent"
                },
                "app_sections": {
                    "setup": "Configuración",
                    "workspace": "Área de trabajo",
                    "automation": "Automatización"
                },
                "user": {
                    "my_profile": "Mi perfil",
                    "log_out": "Finalizar la sesión"
                },
                "helper": {
                    "help_docs": "Documentos de ayuda",
                    "support": "Soporte"
                },
                "skip_button": "Ir al contenido principal",
                "logo": "logotipo",
                "other": "Otro",
                "section": "sección",
                "keyboard_section_headers": {
                    "basic": "Básico",
                    "presentation": "Presentación",
                    "text_editor": "Editor de texto",
                    "script_editor": "Editor de scripts de Robots",
                    "scheduler": "Programador"
                },
                "keyboard_commands": {
                    "basic": {
                        "header": "Métodos abreviados del teclado",
                        "close_dialogs": "Cerrar diálogos"
                    },
                    "presentation": {
                        "next_slide": "Siguiente diapositiva",
                        "prev_slide": "Diapositiva anterior",
                        "first_slide": "Primera diapositiva",
                        "last_slide": "Última diapositiva",
                        "fullscreen": "Pantalla completa",
                        "slide_overview": "Panorama de diapositivas"
                    },
                    "text_editor": {
                        "bold": "En negrita",
                        "italic": "Itálico",
                        "underline": "Subrayar",
                        "select_all": "Seleccionar todo",
                        "redo": "Rehacer",
                        "undo": "Deshacer"
                    },
                    "scheduler": {
                        "highlight_row": "Resaltar una fila",
                        "open_sidepanel": "Abrir un panel lateral",
                        "expand_row": "Expandir la fila actual",
                        "collapse_row": "Contraer la fila actual",
                        "scroll_up": "Desplazarse hacia arriba",
                        "scroll_down": "Desplazarse hacia abajo",
                        "scroll_left": "Desplazarse a la izquierda",
                        "scroll_right": "Desplazarse a la derecha"
                    },
                    "script_editor": {
                        "run": "Ejecutar la celda seleccionada",
                        "stop": "Detener la ejecución",
                        "cell_above": "Seleccionar la celda de arriba",
                        "cell_below": "Seleccionar la celda de abajo",
                        "first_cell": "Seleccionar la primera celda",
                        "last_cell": "Seleccionar la última celda",
                        "add": "Agregar una celda debajo",
                        "delete": "Eliminar la celda seleccionada"
                    },
                    "bubbly_header": "¡Nada aquí todavía!",
                    "bubbly_description": "No admitimos métodos abreviados específicos para esta aplicación, pero si tiene alguna sugerencia, <a href=\"javascript:void(0);\" data-pendo-anchor=\"request-shortcuts\">háganosla saber</a>."
                },
                "shortcut_keys": {
                    "esc": "Esc",
                    "enter": "Intro",
                    "home": "Inicio",
                    "end": "Fin",
                    "space": "Espacio",
                    "shiftKey": "Mayús.",
                    "altKey": "Alt",
                    "optionKey": "Opción",
                    "ctrlKey": "Ctrl"
                },
                "training": {
                    "app_name": "{{appName}} (CAPACITACIÓN)",
                    "app_switcher_product_title": "HIGHBOND (CAPACITACIÓN)"
                }
            }
        }
    }
};
exports.default = locales;
