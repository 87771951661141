import React from 'react';
import config from 'config';
import InternalPageHeader from 'components/common/InternalPageHeader';

export default function ManzamaPOC() {
  return (
    <>
      <InternalPageHeader title="Manzama POC" showAutoSyncTag showBetaTag={false} />
      <iframe frameBorder="0" src={`${config.reportingApiUrl}/boards/embed_url`} height="100%" width="100%" />
    </>
  );
}
