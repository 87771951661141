import React from 'react';
import Button from '@paprika/button';
import { useI18n } from '@paprika/l10n';
import { Link } from 'react-router-dom';
import Settings from '@acl-services/wasabicons/lib/Settings';
import BetaTag from './common/BetaTag';

export default function SidePanelFooter(props: { hasAuthoringPermissions: boolean }) {
  const I18n = useI18n();

  if (!props.hasAuthoringPermissions) {
    return null;
  }

  return (
    <div className="side-panel__footer">
      <Link to="/manage" className="side-panel__manage-button">
        <Button
          kind={Button.types.kind.MINOR}
          data-testid="manage-dashboards"
          size={Button.types.size.MEDIUM}
          icon={<Settings />}
          style={{ textDecoration: 'none' }}
        >
          {I18n.t('Manage dashboards')}
          <BetaTag />
        </Button>
      </Link>
    </div>
  );
}
