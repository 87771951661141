import React from 'react';
import LocalizationService from 'services/LocalizationService';

export const LocalizationContext = React.createContext(new LocalizationService());

export function useLocalization(): LocalizationService {
  return React.useContext(LocalizationContext);
}

export default function LocalizationProvider({ children }: { children: React.ReactNode }): JSX.Element {
  return <LocalizationContext.Provider value={new LocalizationService()}>{children}</LocalizationContext.Provider>;
}
