import React from 'react';
import { useHistory } from 'react-router-dom';
import { useI18n } from '@paprika/l10n';
import Tabs from '@paprika/tabs';
import Popover from '@paprika/popover';
import ActivityCenterIcon from '@acl-services/wasabicons/lib/ActivityCenter';
import Menu from '@acl-services/wasabicons/lib/Menu';
import MenuBack from '@acl-services/wasabicons/lib/MenuBack';
import { DashboardNavigator } from '../services/DashboardNavigator';
import Button from '@paprika/button';
import { Dashboard } from 'services/ReportingApiService';
import { useSession } from 'providers/SessionProvider';
import Spinner from '@paprika/spinner';
import SidePanelFooter from './SidePanelFooter';

import './SidePanel.scss';

export default function SidePanel(props: {
  isLoading: boolean;
  dashboards: Dashboard[];
  selectedDashboard: Dashboard | undefined;
}): JSX.Element {
  const I18n = useI18n();
  const session = useSession();
  const history = useHistory();
  const title = I18n.t('appModuleName');
  const [closed, setClosed] = React.useState<boolean>(false);

  function handleTabClick(index: number): void {
    if (props.selectedDashboard && props.dashboards[index].alias !== props.selectedDashboard.alias) {
      const dashboard = props.dashboards[index];
      DashboardNavigator.navigate(history, dashboard.alias);
    }
  }

  return (
    <div className={'side-panel' + (closed ? ' closed' : '')}>
      <div className="side-panel__header">
        <div className="icon">
          <ActivityCenterIcon aria-label={title} />
        </div>
        <span className="title">{title}</span>
        <Popover isEager isDark align={Popover.types.align.RIGHT}>
          <Popover.Trigger>
            {(handler, a11yAttributes) => (
              <Button.Icon
                className="toggle"
                data-testid="side-panel-toggle"
                kind={Button.types.kind.MINOR}
                size={Button.types.size.LARGE}
                onClick={() => setClosed(!closed)}
                onMouseOver={handler}
                onMouseOut={handler}
                onFocus={handler}
                onBlur={handler}
                {...a11yAttributes}
              >
                {closed ? <Menu /> : <MenuBack />}
              </Button.Icon>
            )}
          </Popover.Trigger>
          <Popover.Content>
            <Popover.Card>{I18n.t(closed ? 'sidenavOpen' : 'sidenavClose')}</Popover.Card>
          </Popover.Content>
          <Popover.Tip />
        </Popover>
      </div>
      {!props.isLoading ? (
        <>
          <Tabs
            isVertical={true}
            index={props.selectedDashboard ? props.dashboards.indexOf(props.selectedDashboard) : -1}
            onClickTab={handleTabClick}
          >
            <Tabs.List className="tabs">
              {props.dashboards.map((item) => (
                <Tabs.Tab key={item.alias} className="side-panel__section" data-testid={`tab-${item.alias}`}>
                  {I18n.t(item.name)}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
          {session.features['authoring'] ? (
            <SidePanelFooter hasAuthoringPermissions={session.hasAuthoringPermissions} />
          ) : null}
        </>
      ) : (
        <Spinner size="small" />
      )}
    </div>
  );
}
