import React from 'react';
import { useI18n } from '@paprika/l10n';
import Tag from '@paprika/tag';

import './BetaTag.scss';

export default function BetaTag() {
  const I18n = useI18n();

  return (
    <div className="beta-tag">
      <Tag theme={Tag.types.theme.LIGHT_BLUE}>{I18n.t('Beta')}</Tag>
    </div>
  );
}
