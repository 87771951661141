import React from 'react';
import GlobalNavigation, {
  AppSwitcherSidePanel,
  CurrentApp,
  HelperWithPendo,
  KeyboardShortcutPanel,
  LaunchpadLink,
  User,
} from '@acl-services/sriracha-global-navigation';
import ReportingApiProvider, { useReportingApi } from 'providers/ReportingApiProvider';
import LaunchpadApiProvider, { useLaunchpadApi } from 'providers/LaunchpadApiProvider';
import { useI18n } from '@paprika/l10n';
import { useSession } from '../providers/SessionProvider';

export function GlobalNav(): JSX.Element {
  const session = useSession();
  const [navData, setNavData] = React.useState<any>();
  const reportingApi = useReportingApi();
  const launchpadApi = useLaunchpadApi();
  const I18n = useI18n();

  React.useEffect(() => {
    async function fetchGlobalNav() {
      if (session.orgId) {
        const navBarJson = await launchpadApi.getGlobalNavBar(session.orgId);
        setNavData(navBarJson.data);
      }
    }

    fetchGlobalNav();
  }, [reportingApi, launchpadApi]);

  const organizationName =
    navData !== undefined
      ? navData.appSwitcherProps.organizations.find(
          (org: { id: string }) => org.id === navData.appSwitcherProps.initialOrganizationId,
        ).name
      : '';

  return (
    <div className={`sriracha ${!navData ? 'loading' : ''}`}>
      {navData !== undefined ? (
        <GlobalNavigation
          locale={navData.locale}
          mainContentSelector="#main"
          currentOrgSubscriptionType={navData.currentOrgSubscriptionType}
        >
          <LaunchpadLink orgName={organizationName} launchpadUrl={navData.links.launchpad} />

          <CurrentApp appModuleName={I18n.t('appModuleName')} appUrl={window.location.origin} />
          <HelperWithPendo helpDocUrl={I18n.t('helpLinks.activityCenters')} supportUrl={navData.links.support} />
          <User
            customerName={navData.customerName}
            username={navData.userFullName}
            userProfileUrl={navData.links.profile}
            logoutUrl={navData.links.logout}
          />

          <AppSwitcherSidePanel {...navData.appSwitcherProps} />
          <KeyboardShortcutPanel />
        </GlobalNavigation>
      ) : (
        <GlobalNavigation mainContentSelector="#main">
          <CurrentApp appModuleName={I18n.t('appModuleName')} appUrl={window.location.origin} />
        </GlobalNavigation>
      )}
    </div>
  );
}

export default function GlobalNavWithProvider(): JSX.Element {
  return (
    <LaunchpadApiProvider>
      <ReportingApiProvider>
        <GlobalNav />
      </ReportingApiProvider>
    </LaunchpadApiProvider>
  );
}
