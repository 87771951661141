import { client } from 'http_client';
import qs from 'qs';
import config from 'config';

export default class HighbondApiService {
  private async fetchJson(url, extraOptions = {}) {
    const response = await client.fetchWithTokenRefresh(url, {
      credentials: 'include',
      ...extraOptions,
    });
    if (response.ok) {
      return await response.json();
    } else {
      // TODO:
    }
  }
  async searchProjects(orgId: string, namesFilter: string[]) {
    const query = qs.stringify({
      fields: {
        projects: 'id,name',
      },
      ...(namesFilter
        ? {
            filter: {
              name: namesFilter,
            },
          }
        : {}),
    });
    const res: any = await this.fetchJson(`${config.apisUrl}/v1/orgs/${orgId}/projects?${query}`);
    return res.data.map((project) => ({ id: project.id, name: project.attributes.name }));
  }
}
