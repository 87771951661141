import React from 'react';
import { isBrowser } from 'react-device-detect';
import InternalPageHeader from 'components/common/InternalPageHeader';
import { useSession } from 'providers/SessionProvider';
import { useI18n } from '@paprika/l10n';
import NoAccess from 'components/common/NoAccess';
import AuthoringContainer from './AuthoringContainer';

import './ManagePage.scss';
import MobileAuthoringNotification from './MobileAuthoringModal';

export default function ManagePage() {
  const I18n = useI18n();
  const session = useSession();

  function renderAuthoringSession(hasAuthoringPermissions: boolean) {
    if (!hasAuthoringPermissions) {
      return <NoAccess />;
    }

    if (!isBrowser) {
      return <MobileAuthoringNotification type="card" onClose={() => console.log('Closed')} />;
    }

    return <AuthoringContainer localeCode={I18n.t('qsLocaleCode')} />;
  }

  return (
    <div className="manage-main">
      <InternalPageHeader title={I18n.t('Manage dashboards')} showAutoSyncTag showBetaTag />
      {renderAuthoringSession(session.hasAuthoringPermissions)}
    </div>
  );
}
