import { JwtHighbondClient } from '@acl-services/jwt-highbond-client';
import config from './config';

export const client = new JwtHighbondClient({
  tokenRefreshUrl: config.tokenRefreshUrl,
  autoRedirect: true,
  loginUrl: config.loginUrl,
});

export const fetchJson = async <T = any>(requestInfo: RequestInfo, requestOptions?: RequestInit): Promise<T> => {
  const opts: RequestInit = {
    credentials: 'include',
    ...requestOptions,
    headers: {
      Accept: 'application/json',
      ...requestOptions?.headers,
    },
  };
  const response = await client.fetchWithTokenRefresh(requestInfo, opts);
  if (response.ok) {
    const data = await response.json();
    return data as T;
  } else {
    const error = new Error(await response.text());
    error.name = response.statusText;
    throw error;
  }
};
