import React from 'react';
import PageHeader from '@acl-services/sriracha-page-header';
import { useI18n } from '@paprika/l10n';
import { isBrowser } from 'react-device-detect';
import DocumentTitle from 'react-document-title';
import { AuthoringDashboard, Dashboard } from 'services/ReportingApiService';
import Button from '@paprika/button';
import EditAlt from '@acl-services/wasabicons/lib/EditAlt';
import { useSession } from 'providers/SessionProvider';
import { useReportingApi } from 'providers/ReportingApiProvider';
import Tooltip from '@mui/material/Tooltip';
interface MainPageHeaderProps {
  onClickEdit: (dashboardId: string) => void;
  dashboard?: Dashboard;
}

export default function MainPageHeader({ dashboard, onClickEdit }: MainPageHeaderProps): JSX.Element {
  const I18n = useI18n();
  const session = useSession();
  const title = I18n.t(dashboard?.name);
  const reportingApi = useReportingApi();

  const docTitle = `${title} - ${I18n.t('titleSuffix')}`;
  const [authorDashboard, setAuthorDashboard] = React.useState<AuthoringDashboard>();

  const isOrphan = authorDashboard && authorDashboard.status === 'orphaned';

  const showEditButton = authorDashboard && authorDashboard.canEdit && isBrowser;

  React.useEffect(() => {
    (async function () {
      if (session.hasAuthoringPermissions && dashboard?.custom) {
        const authoringDashboard = await reportingApi.getDashboard(dashboard.id);
        setAuthorDashboard(authoringDashboard);
      }
    })();
  }, [dashboard]);

  return (
    <DocumentTitle title={docTitle}>
      <PageHeader>
        <PageHeader.Title>{title}</PageHeader.Title>
        <PageHeader.Side>
          <div>
            {showEditButton ? (
              isOrphan ? (
                <Tooltip title={I18n.t('tooltip.orphanedDashboard')} arrow>
                  <span>
                    <Button isDisabled icon={<EditAlt />}>
                      {I18n.t('Edit')}
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Button icon={<EditAlt />} onClick={() => onClickEdit(authorDashboard.analysisId!)}>
                  {I18n.t('Edit')}
                </Button>
              )
            ) : null}
          </div>
        </PageHeader.Side>
      </PageHeader>
    </DocumentTitle>
  );
}
