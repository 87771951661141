import React from 'react';
import { useReportingApi } from 'providers/ReportingApiProvider';
import { useSession } from 'providers/SessionProvider';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { Dashboard } from 'services/ReportingApiService';

type UseDashboardsResult = UseQueryResult<Dashboard[]> & {
  getSelectedDashboard: (dashboardAlias: string) => Dashboard | undefined;
  refreshDashboards: () => void;
};

const queryKey = 'dashboards';

export default function useDashboards(): UseDashboardsResult {
  const queryClient = useQueryClient();

  const session = useSession();
  const reportingApi = useReportingApi();

  const dashboardsQuery = useQuery(queryKey, async () => {
    const { managed, custom } = await reportingApi.getDashboards();
    let dashboards = managed.concat(custom);

    // TEMPORARY RULES FOR SOX DASHBOARD BETA
    const soxEnabled = session.subscriptionState === 'internal' || session.features['sox'];
    dashboards = dashboards.filter((d: Dashboard) => {
      // rule 1 - SOX dashboard is only for internal or beta-enabled orgs
      if (d.alias === 'sox' && !soxEnabled) {
        return false;
      }
      // rule 2 - internal and non-internal orgs have different variants of My Activities
      if (d.alias === 'my_activities' && soxEnabled) {
        return false;
      }
      if (d.alias === 'my_activities_internal' && !soxEnabled) {
        return false;
      }
      return true;
    });

    // re-map internal My Activities dashboard to the regular dashboard path for seamless UX
    let internalMyActivitiesDashboard = dashboards.find((d: Dashboard) => d.alias === 'my_activities_internal');
    if (internalMyActivitiesDashboard) {
      internalMyActivitiesDashboard.alias = 'my_activities';
    }

    return dashboards;
  });

  function refreshDashboards(): void {
    queryClient.invalidateQueries(queryKey);
  }

  const getSelectedDashboard = React.useCallback(
    (alias: string) => {
      return dashboardsQuery.data?.find((d) => d.alias == alias);
    },
    [dashboardsQuery.data],
  );

  return { ...dashboardsQuery, getSelectedDashboard, refreshDashboards };
}
