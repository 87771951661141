import React, { useRef } from 'react';
import { embedSession } from 'amazon-quicksight-embedding-sdk';
import { useReportingApi } from 'providers/ReportingApiProvider';
import { useI18n } from '@paprika/l10n';
import NoAccess from 'components/common/NoAccess';
import PageSpinner from 'components/common/PageSpinner';

interface NoAccessPrompt {
  isActive: boolean;
  message?: string;
}

export default function AuthoringContainer(props: { analysisId?: string; localeCode: string }) {
  const reportingApi = useReportingApi();
  const I18n = useI18n();
  const container = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, setSession] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [noAccess, setNoAccess] = React.useState<NoAccessPrompt>({ isActive: false });

  function getNotificationMessageFromError(error?: string): string | undefined {
    if (!error) {
      return;
    }

    if (error === 'Forbidden') {
      console.error(`User does not have permission to access the authoring session`);
      return; // The "no access" parameter will already tell the user they have no access, they do not need another notification.
    }

    if (error === 'AuthorLimitExceeded') {
      // TODO: Include support link, improve error  message.
      return I18n.t('authoring_sesssion.errors.author_limit_exceeded');
    }

    if (error === 'NotFound') {
      console.error(`Author user was not found in QuickSight`);
    }

    // TODO: Include support link.
    return I18n.t('authoring_sesssion.errors.generic');
  }

  React.useEffect(() => {
    (async function () {
      setSession(null); // session does not support onLoad callback yet, so we use the instance itself to track the loading state

      try {
        const { embedUrl, error } = await reportingApi.getAuthoringSessionUrl(props.analysisId);

        if (embedUrl) {
          const options = {
            url: embedUrl,
            container: container.current,
            locale: props.localeCode,
            scrolling: 'no',
            footerPaddingEnabled: false,
          };
          const session = embedSession(options);
          // session iframe rendering takes ~4 seconds, so we keep spinner until it's ready
          setTimeout(() => {
            setSession(session);
            setIsLoading(false);
          }, 4000);
        } else {
          setNoAccess({
            isActive: true,
            message: getNotificationMessageFromError(error),
          });

          setIsLoading(false);
        }
      } catch (error) {
        console.log(`Unexpected error occurred when getting embedded authoring session`, error);

        setNoAccess({
          isActive: true,
          message: getNotificationMessageFromError('Unexpected'),
        });

        setIsLoading(false);
      }
    })();
  }, [reportingApi]);

  return (
    <>
      <div id="embeddingContainer" ref={container}>
        {noAccess.isActive ? <NoAccess description={noAccess.message} /> : null}
      </div>
      {isLoading ? <PageSpinner /> : null}
    </>
  );
}
