"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Do not edit this file, it is automatically generated by @paprika/build-translations
var locales = {
    "ja": {
        "translation": {
            "global_navigation": {
                "app_switcher_title": "Diligent HighBond",
                "navigation_items": {
                    "logo_org_name": "Launchpad に進む",
                    "logo": "Launchpad に戻る",
                    "app_switcher": "アプリを切り替える",
                    "announcer": "発表",
                    "helper": "ヘルプとサポート",
                    "user": "サインイン: {{username}}",
                    "user_with_customer": "{{username}} {{customerName}}",
                    "sign_in": "サインイン",
                    "keyboard_shortcut_panel_tooltip": "キーボードショートカット [Ctrl + /]"
                },
                "apps": {
                    "academy": "アカデミー",
                    "community": "コミュニティ",
                    "projects": "プロジェクト",
                    "results": "リザルト",
                    "storyboards": "ストーリーボード",
                    "mission_control": "ミッション コントロール",
                    "risks": "ストラテジー",
                    "reports": "レポート",
                    "launchpad": "Launchpad",
                    "robots": "ロボット",
                    "frameworks": "フレームワーク",
                    "compliance_maps": "コンプライアンス マップ",
                    "assurance_plans": "保証計画",
                    "project_scheduler": "スケジューラ",
                    "issue_tracker": "問題の追跡ツール",
                    "task_tracker": "タスクの追跡ツール",
                    "project_timesheets": "タイムシート",
                    "dashboard": "{{module}}ダッシュボード",
                    "master_library": "構成"
                },
                "external": {
                    "diligent": "Diligent"
                },
                "app_sections": {
                    "setup": "セットアップ",
                    "workspace": "ワークスペース",
                    "automation": "自動化"
                },
                "user": {
                    "my_profile": "マイ プロファイル",
                    "log_out": "ログアウト"
                },
                "helper": {
                    "help_docs": "ヘルプ ドキュメント",
                    "support": "サポート"
                },
                "skip_button": "メイン コンテンツまでスキップ",
                "logo": "ロゴ",
                "other": "その他",
                "section": "セクション",
                "keyboard_section_headers": {
                    "basic": "基本",
                    "presentation": "表示",
                    "text_editor": "テキストエディター",
                    "script_editor": "ロボット スクリプト エディター",
                    "scheduler": "スケジューラ"
                },
                "keyboard_commands": {
                    "basic": {
                        "header": "キーボード ショートカット",
                        "close_dialogs": "ダイアログを閉じる"
                    },
                    "presentation": {
                        "next_slide": "次のスライド",
                        "prev_slide": "前のスライド",
                        "first_slide": "最初のスライド",
                        "last_slide": "最終スライド",
                        "fullscreen": "全画面",
                        "slide_overview": "スライド概要"
                    },
                    "text_editor": {
                        "bold": "太字",
                        "italic": "斜体",
                        "underline": "下線",
                        "select_all": "すべて選択",
                        "redo": "やり直す",
                        "undo": "元に戻す"
                    },
                    "scheduler": {
                        "highlight_row": "行を強調",
                        "open_sidepanel": "スライドパネルを開く",
                        "expand_row": "現在の行を展開",
                        "collapse_row": "現在の行を圧縮",
                        "scroll_up": "上にスクロール",
                        "scroll_down": "下にスクロール",
                        "scroll_left": "左にスクロール",
                        "scroll_right": "右にスクロール"
                    },
                    "script_editor": {
                        "run": "選択したセルを実行",
                        "stop": "実行を停止",
                        "cell_above": "上のセルを選択",
                        "cell_below": "下のセルを選択",
                        "first_cell": "最初のセルを選択",
                        "last_cell": "最後のセルを選択",
                        "add": "以下のセルを追加",
                        "delete": "選択したセルを削除"
                    },
                    "bubbly_header": "まだ入力がありません!",
                    "bubbly_description": "このアプリで特定のショートカットはサポートされていませんが、ご提案いただける場合、<a href=\"javascript:void(0);\" data-pendo-anchor=\"request-shortcuts\">当社までお知らせ</a>ください。"
                },
                "shortcut_keys": {
                    "esc": "Esc",
                    "enter": "Enter",
                    "home": "Home",
                    "end": "End",
                    "space": "Space",
                    "shiftKey": "Shift",
                    "altKey": "Alt",
                    "optionKey": "オプション",
                    "ctrlKey": "Ctrl"
                },
                "training": {
                    "app_name": "{{appName}} (トレーニング)",
                    "app_switcher_product_title": "HIGHBOND (トレーニング)"
                }
            }
        }
    }
};
exports.default = locales;
