import React from 'react';
import { isBrowser } from 'react-device-detect';
import { useI18n } from '@paprika/l10n';
import Takeover from '@paprika/takeover';
import AuthoringContainer from './AuthoringContainer';
import MobileAuthoringNotification from './MobileAuthoringModal';
import Icon from '@paprika/icon/lib/InfoCircle';
import BetaTag from 'components/common/BetaTag';

import './AuthoringTakeover.scss';

export default function AuthoringTakeover(props: { analysisId: string; onClose: () => void }) {
  const { analysisId, onClose } = props;
  const I18n = useI18n();

  return isBrowser ? (
    <Takeover className="manage-overlay" isOpen={true} onClose={onClose} isFullWidth>
      <Takeover.Header
        className="header"
        tools={
          <span>
            <Icon aria-label="info" role="img" className="popover-info" />
            {I18n.t('editDashboardTooltip')}
          </span>
        }
      >
        <span className="takeover-title">
          {I18n.t('Edit dashboard')}
          <BetaTag />
        </span>
      </Takeover.Header>
      <AuthoringContainer analysisId={analysisId} localeCode={I18n.t('qsLocaleCode')} />
    </Takeover>
  ) : (
    <MobileAuthoringNotification type="modal" onClose={onClose} />
  );
}
