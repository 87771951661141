import React from 'react';
import HighbondApiService from 'services/HighbondApiService';

export const HighbondApiContext = React.createContext(new HighbondApiService());

export function useHighbondApi(): HighbondApiService {
  return React.useContext(HighbondApiContext);
}

export default function HighbondApiProvider({ children }: { children: React.ReactNode }): JSX.Element {
  return <HighbondApiContext.Provider value={new HighbondApiService()}>{children}</HighbondApiContext.Provider>;
}
