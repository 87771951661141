import React from 'react';
import Button from '@paprika/button';
import Card from '@paprika/card';
import NotificationCard from '@paprika/notification-card';
import { useI18n } from '@paprika/l10n';
import BubblyError from '@acl-services/wasabicons/lib/BubblyError';
import config from 'config';

import './NoAccess.scss';

export default function NoAccess({ title, description }: { title?: string; description?: string }): JSX.Element {
  const I18n = useI18n();

  const titleText = title ?? I18n.t('noAccess.title');
  const descriptionText = description ?? I18n.t('noAccess.content');

  return (
    <Card className="no-access-card">
      <NotificationCard className="no-access">
        <NotificationCard.Image align="center">
          <BubblyError />
        </NotificationCard.Image>
        <NotificationCard.Header level={2}>{titleText}</NotificationCard.Header>
        <NotificationCard.Body className="description">{descriptionText}</NotificationCard.Body>
        <Button.Link kind={Button.types.kind.PRIMARY} href={config.launchpadUrl}>
          {I18n.t('noAccess.action')}
        </Button.Link>
      </NotificationCard>
    </Card>
  );
}
