"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Do not edit this file, it is automatically generated by @paprika/build-translations
var locales = {
    "en": {
        "translation": {
            "global_navigation": {
                "app_switcher_title": "Diligent HighBond",
                "navigation_items": {
                    "logo_org_name": "Go to Launchpad",
                    "logo": "Back to Launchpad",
                    "app_switcher": "Switch Apps",
                    "announcer": "Announcements",
                    "helper": "Help and Support",
                    "user": "Signed in: {{username}}",
                    "user_with_customer": "{{username}} {{customerName}}",
                    "sign_in": "Sign in",
                    "keyboard_shortcut_panel_tooltip": "Keyboard shortcut [Ctrl + /]"
                },
                "apps": {
                    "academy": "Academy",
                    "community": "Community",
                    "projects": "Projects",
                    "results": "Results",
                    "storyboards": "Storyboards",
                    "mission_control": "Mission Control",
                    "risks": "Strategy",
                    "reports": "Reports",
                    "launchpad": "Launchpad",
                    "robots": "Robots",
                    "frameworks": "Frameworks",
                    "compliance_maps": "Compliance Maps",
                    "assurance_plans": "Assurance Plans",
                    "project_scheduler": "Scheduler",
                    "issue_tracker": "Issue Tracker",
                    "task_tracker": "Task Tracker",
                    "project_timesheets": "Timesheets",
                    "dashboard": "{{module}} Dashboard",
                    "master_library": "Configuration"
                },
                "external": {
                    "diligent": "Diligent"
                },
                "app_sections": {
                    "setup": "Setup",
                    "workspace": "Workspace",
                    "automation": "Automation"
                },
                "user": {
                    "my_profile": "My Profile",
                    "log_out": "Log Out"
                },
                "helper": {
                    "help_docs": "Help Docs",
                    "support": "Support"
                },
                "skip_button": "Skip to main content",
                "logo": "logo",
                "other": "Other",
                "section": "section",
                "keyboard_section_headers": {
                    "basic": "Basic",
                    "presentation": "Presentation",
                    "text_editor": "Text editor",
                    "script_editor": "Robots script editor",
                    "scheduler": "Scheduler"
                },
                "keyboard_commands": {
                    "basic": {
                        "header": "Keyboard shortcuts",
                        "close_dialogs": "Close dialogs"
                    },
                    "presentation": {
                        "next_slide": "Next slide",
                        "prev_slide": "Previous slide",
                        "first_slide": "First slide",
                        "last_slide": "Last slide",
                        "fullscreen": "Fullscreen",
                        "slide_overview": "Slide overview"
                    },
                    "text_editor": {
                        "bold": "Bold",
                        "italic": "Italic",
                        "underline": "Underline",
                        "select_all": "Select all",
                        "redo": "Redo",
                        "undo": "Undo"
                    },
                    "scheduler": {
                        "highlight_row": "Highlight a row",
                        "open_sidepanel": "Open a sidepanel",
                        "expand_row": "Expand the current row",
                        "collapse_row": "Collapse the current row",
                        "scroll_up": "Scroll up",
                        "scroll_down": "Scroll down",
                        "scroll_left": "Scroll left",
                        "scroll_right": "Scroll right"
                    },
                    "script_editor": {
                        "run": "Run selected cell",
                        "stop": "Stop execution",
                        "cell_above": "Select cell above",
                        "cell_below": "Select cell below",
                        "first_cell": "Select first cell",
                        "last_cell": "Select last cell",
                        "add": "Add cell below",
                        "delete": "Delete selected cell"
                    },
                    "bubbly_header": "Nothing here yet!",
                    "bubbly_description": "We don't support specific shortcuts for this app but if you have any suggestions, please <a href=\"javascript:void(0);\" data-pendo-anchor=\"request-shortcuts\">let us know</a>."
                },
                "shortcut_keys": {
                    "esc": "Esc",
                    "enter": "Enter",
                    "home": "Home",
                    "end": "End",
                    "space": "Space",
                    "shiftKey": "Shift",
                    "altKey": "Alt",
                    "optionKey": "Option",
                    "ctrlKey": "Ctrl"
                },
                "training": {
                    "app_name": "{{appName}} (TRAINING)",
                    "app_switcher_product_title": "HIGHBOND (TRAINING)"
                }
            }
        }
    }
};
exports.default = locales;
