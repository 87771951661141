import React from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from '@acl-services/sriracha-page-header';
import Button from '@paprika/button';
import Breadcrumbs from '@paprika/breadcrumbs';
import Tag from '@paprika/tag';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@paprika/icon/lib/InfoCircle';
import RefreshCircle from '@acl-services/wasabicons/lib/RefreshCircle';
import { useI18n } from '@paprika/l10n';
import useDashboards from 'hooks/useDashboards';
import BetaTag from './BetaTag';
import Popover from '@paprika/popover';

import './InternalPageHeader.scss';

interface InternalPageHeader {
  title: string;
  showAutoSyncTag: boolean;
  showBetaTag: boolean;
}

export default function InternalPageHeader(props: InternalPageHeader): JSX.Element {
  const { title, showAutoSyncTag, showBetaTag } = props;
  const { refreshDashboards } = useDashboards();

  const I18n = useI18n();
  const history = useHistory();
  const breadcrumbUrl = '/';

  function onNavigateBack() {
    refreshDashboards();
    history.push(breadcrumbUrl);
  }

  return (
    <PageHeader>
      <PageHeader.Icon>
        <PageHeader.Icon.Kinds.ACTIVITY_CENTER />
      </PageHeader.Icon>
      <PageHeader.Breadcrumbs>
        <Breadcrumbs>
          <Breadcrumbs.Link
            component={Button.Link}
            onClick={onNavigateBack}
            onKeyUp={(event: KeyboardEvent) => event.key === ' ' && onNavigateBack()}
          >
            {I18n.t('appModuleName')}
          </Breadcrumbs.Link>
        </Breadcrumbs>
      </PageHeader.Breadcrumbs>
      <PageHeader.Title>
        <span className="header__title">
          {title}
          <Popover isEager className="popover">
            <Popover.Trigger>
              {(handler, a11yAttributes) => (
                <Icon
                  onMouseOver={handler}
                  onMouseOut={handler}
                  onFocus={handler}
                  onBlur={handler}
                  tabIndex={0}
                  aria-label="info"
                  role="img"
                  size="20px"
                  {...a11yAttributes}
                />
              )}
            </Popover.Trigger>
            <Popover.Content>
              <Popover.Card>
                <p
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('manageDashboardsTooltip', { linkToHelpGuide: I18n.t('helpLinks.authoring') }),
                  }}
                />
              </Popover.Card>
            </Popover.Content>
            <Popover.Tip />
          </Popover>
          {showBetaTag && <BetaTag />}
        </span>
      </PageHeader.Title>

      {showAutoSyncTag && (
        <PageHeader.Side>
          <Tooltip title={I18n.t('tooltip.dashboardAutoSync')} arrow className="sync">
            <span>
              <Tag avatar={<RefreshCircle size="24px" />} theme={Tag.types.theme.GREEN} size={Tag.types.size.LARGE}>
                {I18n.t('Auto-sync')}
              </Tag>
            </span>
          </Tooltip>
        </PageHeader.Side>
      )}
    </PageHeader>
  );
}
