"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Config = void 0;
var domain = ((_a = window.location.origin.match(/\.highbond((?:-\w+)+)?\.(local|com)$/)) === null || _a === void 0 ? void 0 : _a.shift()) || '';
var isLocalhost = !domain;
var authHost = isLocalhost ? window.location.host : "accounts" + domain;
exports.Config = {
    tokenRefreshUrl: window.location.protocol + "//" + authHost + "/api/token/refresh",
    autoRedirect: false,
    loginUrl: window.location.protocol + "//" + authHost + "/login",
    noSubdomain: false,
    hostname: undefined,
};
