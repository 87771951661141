"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Do not edit this file, it is automatically generated by @paprika/build-translations
var locales = {
    "fr": {
        "translation": {
            "global_navigation": {
                "app_switcher_title": "Diligent HighBond",
                "navigation_items": {
                    "logo_org_name": "Aller à la Barre de lancement",
                    "logo": "Retour vers la Barre de lancement",
                    "app_switcher": "Basculer d'une appli à l'autre",
                    "announcer": "Annonces",
                    "helper": "Aide et Assistance",
                    "user": "Connecté(e) : {{username}}",
                    "user_with_customer": "{{username}} {{customerName}}",
                    "sign_in": "Connexion",
                    "keyboard_shortcut_panel_tooltip": "Raccourcis clavier [Ctrl + /]"
                },
                "apps": {
                    "academy": "Académie",
                    "community": "Communauté",
                    "projects": "Projets",
                    "results": "Résultats",
                    "storyboards": "Storyboards",
                    "mission_control": "Suivi des contrôles",
                    "risks": "Stratégie",
                    "reports": "Rapports",
                    "launchpad": "Barre de lancement",
                    "robots": "Robots",
                    "frameworks": "Cadres",
                    "compliance_maps": "Cartes de conformité",
                    "assurance_plans": "Plans d'assurance",
                    "project_scheduler": "Planificateur",
                    "issue_tracker": "Outil de suivi des problèmes",
                    "task_tracker": "Outil de suivi des tâches",
                    "project_timesheets": "Relevés de temps",
                    "dashboard": "Tableau de bord pour {{module}}",
                    "master_library": "Configuration"
                },
                "external": {
                    "diligent": "Diligent"
                },
                "app_sections": {
                    "setup": "Configuration",
                    "workspace": "Environnement de travail",
                    "automation": "Automatisation"
                },
                "user": {
                    "my_profile": "Mon profil",
                    "log_out": "Déconnexion"
                },
                "helper": {
                    "help_docs": "Documentation d'aide",
                    "support": "Assistance"
                },
                "skip_button": "Passer au contenu principal",
                "logo": "logo",
                "other": "Autres",
                "section": "section",
                "keyboard_section_headers": {
                    "basic": "Basique",
                    "presentation": "Présentation",
                    "text_editor": "Éditeur de texte",
                    "script_editor": "Éditeur de scripts Robots",
                    "scheduler": "Planificateur"
                },
                "keyboard_commands": {
                    "basic": {
                        "header": "Raccourcis clavier",
                        "close_dialogs": "Fermer les dialogues"
                    },
                    "presentation": {
                        "next_slide": "Diapositive suivante",
                        "prev_slide": "Diapositive précédente",
                        "first_slide": "Première diapositive",
                        "last_slide": "Dernière diapositive",
                        "fullscreen": "Plein écran",
                        "slide_overview": "Vue d'ensemble des diapositives"
                    },
                    "text_editor": {
                        "bold": "Gras",
                        "italic": "Italique",
                        "underline": "Souligner",
                        "select_all": "Sélectionner tout",
                        "redo": "Répéter",
                        "undo": "Annuler"
                    },
                    "scheduler": {
                        "highlight_row": "Surligner une ligne",
                        "open_sidepanel": "Ouvrir un panneau latéral",
                        "expand_row": "Développer la ligne actuelle",
                        "collapse_row": "Réduire la ligne actuelle",
                        "scroll_up": "Défiler vers le haut",
                        "scroll_down": "Défiler vers le bas",
                        "scroll_left": "Défiler vers la gauche",
                        "scroll_right": "Défiler vers la droite"
                    },
                    "script_editor": {
                        "run": "Exécuter la cellule sélectionnée",
                        "stop": "Arrêter l'exécution",
                        "cell_above": "Sélectionner la cellule au-dessus",
                        "cell_below": "Sélectionner la cellule en dessous",
                        "first_cell": "Sélectionner la première cellule",
                        "last_cell": "Sélectionner la dernière cellule",
                        "add": "Ajouter une cellule en dessous",
                        "delete": "Supprimer la cellule sélectionnée"
                    },
                    "bubbly_header": "Rien encore !",
                    "bubbly_description": "Nous ne prenons en charge les raccourcis spécifiques pour cette application, cependant si vous avez des suggestions, veuillez <a href=\"javascript:void(0);\" data-pendo-anchor=\"request-shortcuts\">nous les communiquer</a>."
                },
                "shortcut_keys": {
                    "esc": "Échap",
                    "enter": "Entrée",
                    "home": "Origine",
                    "end": "Fin",
                    "space": "Espace",
                    "shiftKey": "Maj",
                    "altKey": "Alt",
                    "optionKey": "Option",
                    "ctrlKey": "Ctrl"
                },
                "training": {
                    "app_name": "{{appName}} (FORMATION)",
                    "app_switcher_product_title": "HIGHBOND (FORMATION)"
                }
            }
        }
    }
};
exports.default = locales;
