import React, { useRef } from 'react';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import PageSpinner from 'components/common/PageSpinner';
import isEqual from 'lodash.isequal';

export type QSParameter = { name: string; value: any };
export type ParametersChangedEvent = { parameters: QSParameter[]; changedParameters: QSParameter[] };

export default function QuicksightEmbeddingContainer(props: {
  embedUrl: string;
  localeCode: string;
  parameters: Record<string, string>;
  sheetId: string;
  resetDisabled: boolean;
  onLoad: (embeddedSession: any) => void;
  onParametersChanged: (ParametersChangeEvent) => void;
}) {
  const [dashboard, setDashboard] = React.useState<any>();
  const [dashboardLoaded, setDashboardLoaded] = React.useState<boolean>(false);
  const [parameters, setParameters] = React.useState<Record<string, string>>({});
  const container = useRef(null);
  const loadCallback = React.useRef<() => void>();
  const parametersChangeCallback = React.useRef<(ParametersChangedEvent) => void>();

  parametersChangeCallback.current = (event: ParametersChangedEvent) => {
    setParameters({ ...parameters, ...Object.fromEntries(event.parameters.map(({ name, value }) => [name, value])) });
    props.onParametersChanged(event);
  };

  loadCallback.current = () => {
    setDashboardLoaded(true);
    props.onLoad(dashboard);
  };

  React.useEffect(() => {
    setDashboardLoaded(false);
    const options = {
      url: props.embedUrl,
      container: container.current,
      scrolling: 'no',
      undoRedoDisabled: true,
      resetDisabled: props.resetDisabled,
      footerPaddingEnabled: false,
      loadCallback: () => loadCallback.current!(),
      parametersChangeCallback: (event: ParametersChangedEvent) => parametersChangeCallback.current!(event),
      locale: props.localeCode,
      parameters: props.parameters,
      sheetId: props.sheetId || undefined,
    };
    const dashboard = embedDashboard(options);
    setDashboard(dashboard);
    setParameters(props.parameters);
  }, [props.embedUrl]);

  React.useEffect(() => {
    if (dashboardLoaded) {
      const changedParameterEntries = Object.entries(props.parameters).filter(
        ([name, value]) => !(name in parameters) || !isEqual(parameters[name], value),
      );
      if (changedParameterEntries.length) {
        dashboard.setParameters(Object.fromEntries(changedParameterEntries));
      }
    }
  }, [props.parameters, parameters, dashboardLoaded]);

  return (
    <>
      <div id="embeddingContainer" key={props.embedUrl} ref={container} />
      {!dashboardLoaded ? <PageSpinner /> : null}
    </>
  );
}
