import { createTheme } from '@mui/material/styles';
import { PaletteColor, PaletteColorOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    neutral: PaletteColorOptions;
    link: PaletteColorOptions;
  }
  interface Palette {
    neutral: PaletteColor;
    link: PaletteColor;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2f3b4d',
    },
    background: {
      default: grey['100'],
    },
    link: {
      main: '#0063C5',
    },
    neutral: {
      light: grey['100'],
      main: grey['300'],
      dark: grey['600'],
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Lato', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 12.25,
    subtitle1: {
      fontWeight: 600,
    },
    button: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.primary': {
            backgroundColor: '#53a77d',
            ':hover': {
              backgroundColor: '#42996D',
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          backgroundColor: 'white',
          border: '1px solid rgb(215, 215, 215) !important',
          borderRadius: '3px',
          color: 'rgb(63, 61, 60)',
          padding: '0 8px',
          fontSize: '14px',
          'label + &': {
            marginTop: '0',
          },
          '&::before, &::after': {
            border: 'none !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'none',
          transition: 'none',
          position: 'relative',
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#3f3d3c',
          marginBottom: '4px',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: '1px solid #D7D7D7',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0 10px',
          height: '36px',
          fontSize: '14px',
        },
        head: {
          backgroundColor: '#f0f0f0',
          fontWeight: 'bold',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 500,
      },
      styleOverrides: {
        tooltip: {
          fontSize: '14px',
        },
        popper: {
          maxWidth: '180px',
        },
      },
    },
  },
});

export default theme;
