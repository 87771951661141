import React from 'react';
import ApiService, { Session } from '../services/ReportingApiService';

export const SessionContext = React.createContext({} as Session);

export function useSession(): Session {
  return React.useContext(SessionContext);
}

export default function SessionProvider({ children }: { children: React.ReactNode }): JSX.Element | null {
  const [session, setSession] = React.useState<Session>();

  React.useEffect(() => {
    new ApiService().getSession().then((session) => {
      setSession({
        ...session,
        locale: session.locale || window.navigator.language.slice(0, 2) || 'en',
      });
    });
  }, []);

  return session ? <SessionContext.Provider value={session}>{children}</SessionContext.Provider> : null;
}
