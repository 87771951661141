import React from 'react';
import ReactDOM from 'react-dom';
import config from 'config';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import SessionProvider from './providers/SessionProvider';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'hb-edit-action-panel': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      'hb-edit-issue-panel': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MOCKS === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { initializeServer } = require('./mocks/server');
  initializeServer();
}

if (process.env.NODE_ENV === 'production') {
  const pendoScript = document.createElement('script');
  pendoScript.type = 'text/javascript';
  pendoScript.crossOrigin = 'use-credentials';
  pendoScript.src = config.pendoMonitorScript;
  document.head.appendChild(pendoScript);
}

Object.entries(config.microFrontends).forEach(([key, { url }]) => {
  const editIssueScript = document.createElement('script');
  editIssueScript.type = 'text/javascript';
  editIssueScript.crossOrigin = 'use-credentials';
  editIssueScript.src = url;
  document.head.appendChild(editIssueScript);
});

const render = () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <SessionProvider>
        <App />
      </SessionProvider>
    </ThemeProvider>,
    document.getElementById('root'),
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}
