"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Do not edit this file, it is automatically generated by @paprika/build-translations
var locales = {
    "pt": {
        "translation": {
            "global_navigation": {
                "app_switcher_title": "Diligent HighBond",
                "navigation_items": {
                    "logo_org_name": "Ir para o Launchpad",
                    "logo": "Voltar para o Launchpad",
                    "app_switcher": "Alternar Aplicativos",
                    "announcer": "Lançamentos",
                    "helper": "Ajuda e Suporte",
                    "user": "Entrou: {{username}}",
                    "user_with_customer": "{{username}} {{customerName}}",
                    "sign_in": "Entrar",
                    "keyboard_shortcut_panel_tooltip": "Atalho de teclado [Ctrl + /]"
                },
                "apps": {
                    "academy": "Academia",
                    "community": "Comunidade",
                    "projects": "Projetos",
                    "results": "Resultados",
                    "storyboards": "Storyboards",
                    "mission_control": "Controle da Missão",
                    "risks": "Estratégia",
                    "reports": "Relatórios",
                    "launchpad": "Launchpad",
                    "robots": "Robôs",
                    "frameworks": "Estruturas",
                    "compliance_maps": "Mapas de Conformidade",
                    "assurance_plans": "Planos de Garantia",
                    "project_scheduler": "Agendador",
                    "issue_tracker": "Rastreador de Problemas",
                    "task_tracker": "Rastreador de Tarefas",
                    "project_timesheets": "Quadros de Horário",
                    "dashboard": "Painel do {{module}}",
                    "master_library": "Configuração"
                },
                "external": {
                    "diligent": "Diligent"
                },
                "app_sections": {
                    "setup": "Configuração",
                    "workspace": "Área de trabalho",
                    "automation": "Automação"
                },
                "user": {
                    "my_profile": "Meu Perfil",
                    "log_out": "Efetuar Logout"
                },
                "helper": {
                    "help_docs": "Documentos de Ajuda",
                    "support": "Suporte"
                },
                "skip_button": "Ir para conteúdo principal",
                "logo": "logotipo",
                "other": "Outro",
                "section": "seção",
                "keyboard_section_headers": {
                    "basic": "Básico",
                    "presentation": "Apresentação",
                    "text_editor": "Editor de texto",
                    "script_editor": "Editor de script do Robôs",
                    "scheduler": "Agendador"
                },
                "keyboard_commands": {
                    "basic": {
                        "header": "Atalhos de teclado",
                        "close_dialogs": "Fechar caixa de diálogo"
                    },
                    "presentation": {
                        "next_slide": "Próximo slide",
                        "prev_slide": "Slide anterior",
                        "first_slide": "Primeiro slide",
                        "last_slide": "Último slide",
                        "fullscreen": "Tela cheia",
                        "slide_overview": "Visão geral do slide"
                    },
                    "text_editor": {
                        "bold": "Negrito",
                        "italic": "Itálico",
                        "underline": "Sublinhado",
                        "select_all": "Selecionar tudo",
                        "redo": "Refazer",
                        "undo": "Desfazer"
                    },
                    "scheduler": {
                        "highlight_row": "Realçar uma linha",
                        "open_sidepanel": "Abrir um painel lateral",
                        "expand_row": "Expandir a linha atual",
                        "collapse_row": "Recolher a linha atual",
                        "scroll_up": "Rolar para cima",
                        "scroll_down": "Rolar para baixo",
                        "scroll_left": "Rolar para a esquerda",
                        "scroll_right": "Rolar para a direita"
                    },
                    "script_editor": {
                        "run": "Executar célula selecionada",
                        "stop": "Parar a execução",
                        "cell_above": "Selecionar célula acima",
                        "cell_below": "Selecionar célula abaixo",
                        "first_cell": "Selecionar primeira célula",
                        "last_cell": "Selecionar última célula",
                        "add": "Adicionar célula abaixo",
                        "delete": "Excluir célula selecionada"
                    },
                    "bubbly_header": "Ainda não!",
                    "bubbly_description": "Não há compatibilidade com atalhos específicos para esse aplicativo, mas se tiver sugestões, <a href=\"javascript:void(0);\" data-pendo-anchor=\"request-shortcuts\">fale conosco</a>."
                },
                "shortcut_keys": {
                    "esc": "Esc",
                    "enter": "Enter",
                    "home": "Home",
                    "end": "End",
                    "space": "Espaço",
                    "shiftKey": "Shift",
                    "altKey": "Alt",
                    "optionKey": "Opção",
                    "ctrlKey": "Ctrl"
                },
                "training": {
                    "app_name": "{{appName}} (TREINAMENTO)",
                    "app_switcher_product_title": "HighBond (TREINAMENTO)"
                }
            }
        }
    }
};
exports.default = locales;
