import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import config from 'config';
import { useReportingApi } from 'providers/ReportingApiProvider';
import { Dashboard } from 'services/ReportingApiService';
import { useLocalization } from 'providers/LocalizationProvider';
import QuicksightEmbeddingContainer, { ParametersChangedEvent } from './QuicksightEmbeddingContainer';
import NoAccess from 'components/common/NoAccess';
import { useI18n } from '@paprika/l10n';
import Toast from '@paprika/toast';
import { ActionTrigger } from 'services/ActionTrigger';
import PageSpinner from 'components/common/PageSpinner';
import SoxProjectInput from './SoxProjectInput';
import { DashboardNavigator, DashboardOptions } from 'services/DashboardNavigator';
import { useSession } from 'providers/SessionProvider';

import './ActivityCenter.scss';

const emptyArrayString = JSON.stringify([]);
const AllInputParams = [
  {
    key: 'SoxProjectName',
    renderComponent: (initialValue: string[], callback: (newValue: string[]) => void) => (
      <SoxProjectInput initalValue={initialValue ?? []} callback={callback} />
    ),
    defaultValue: [],
  },
];
export interface EditParameters {
  type: 'issues' | 'actions';
  id: string;
}

export default function ActivityCenter(props: { dashboards: Dashboard[]; selectedDashboardOptions: DashboardOptions }) {
  const I18n = useI18n();
  const history = useHistory();
  const session = useSession();
  const reportingApi = useReportingApi();
  const localizationService = useLocalization();
  const dashboardAlias = props.selectedDashboardOptions.alias;

  // TEMP CLIENT-SIDE SOLUTION FOR PER-DASHBOARD REQUIRED INPUT PARAMS
  const dashboardInputParams = dashboardAlias == 'sox' ? AllInputParams.filter((p) => p.key === 'SoxProjectName') : [];

  const [embedUrl, setEmbedUrl] = React.useState<string>();
  const [parameters, setParameters] = React.useState<{ [name: string]: any }>({
    IssueOverallStatus: I18n.i18n.getFixedT(session.orgLocale)('defaultIssueStatus'),
  });
  const [l10nParams, setL10nParams] = React.useState<{ [name: string]: string }>();
  const [inputParamValues, setInputParamValues] = React.useState<{ [name: string]: string[] }>({});
  const [noAccess, setNoAccess] = React.useState(false);
  const [embeddedSession, setEmbeddedSession] = React.useState<any>();
  const [pendingDashboardParams, setPendingDashboardParams] = React.useState<any>();
  const [actionId, setActionId] = React.useState<string>();
  const [issueId, setIssueId] = React.useState<string>();
  const [selectedId, setSelectedId] = React.useState<string>();
  const issueRef = React.useRef<HTMLElement>(null);
  const actionRef = React.useRef<HTMLElement>(null);

  const actions = {
    EditIssue: (issueId) => {
      setIssueId(issueId);
    },

    EditAction: (actionId) => {
      setActionId(actionId);
    },

    NavigateToDashboard: (destination, filterName, filterValue) => {
      const [alias, sheetIndex] = destination.split(',');
      DashboardNavigator.navigate(history, alias, +(sheetIndex || 0), filterName ? { [filterName]: filterValue } : {});
    },
  };

  let actionTrigger: ActionTrigger | null = null;

  // tmp logic for SOX setup toast - show toast on My Activities dashboard IF SOX dashboard is available AND SOX Project Name is not set
  function shouldShowSoxSetupToast() {
    return (
      dashboardAlias === 'my_activities' &&
      props.dashboards.find((d) => d.alias === 'sox') &&
      parameters.SoxProjectName.length === 0
    );
  }

  function onSessionLoaded(embeddedSession: any) {
    setEmbeddedSession(embeddedSession);
  }

  function applyDashboardParams(dashboardParams: any) {
    if (dashboardParams.sheetId) {
      embeddedSession.navigateToSheet(dashboardParams.sheetId);
    }
  }

  function navigateToDashboard(dashboardId: string) {
    setSelectedId(dashboardId);
    setPendingDashboardParams({
      sheetId: props.selectedDashboardOptions.sheetId,
    });
    embeddedSession.navigateToDashboard({
      dashboardId,
    });
  }

  if (config.multiDashboardSessionsEnabled && embeddedSession && selectedId != props.selectedDashboardOptions.id) {
    navigateToDashboard(props.selectedDashboardOptions.id);
  }

  function onParametersChanged({ changedParameters }: ParametersChangedEvent) {
    DashboardNavigator.fixHistoryIfNeeded(history);
    // QuickSight changes parameters individually when a Navigation action is triggered. Hopefully that doesn't change.
    if (changedParameters.length === 1) {
      if (!actionTrigger) {
        actionTrigger = new ActionTrigger(actions);
        setTimeout(() => (actionTrigger = null), 1000);
      }
      const [parameter] = changedParameters;
      if (parameter.name === 'HBActionName') {
        actionTrigger.setActionName(parameter.value);
      } else if (parameter.name.startsWith('HBActionParam')) {
        actionTrigger.setParam(parseInt(parameter.name.substr(13)) - 1, parameter.value);
      }
    } else if (!changedParameters.length && pendingDashboardParams) {
      // empty params come after navigation
      applyDashboardParams(pendingDashboardParams);
      setPendingDashboardParams(null);
    }
  }

  function getEffectiveInputParamValue(inputParamValue: string[]): any {
    return inputParamValue.length > 0 ? inputParamValue : '';
  }

  function applyInputParamValue(qsParameterName: string, newValues: string[]): void {
    const inputParamValue = newValues.map((newValue) => newValue.trim());
    const newValuesString = JSON.stringify(inputParamValue);
    localStorage.setItem(`quicksight.${qsParameterName}`, newValuesString);
    setParameters({ ...parameters, [qsParameterName]: getEffectiveInputParamValue(inputParamValue) });
  }

  function setLocalStorageEmptyArray(key: string): string {
    localStorage.setItem(key, emptyArrayString);
    return emptyArrayString;
  }

  /** Get specified parameter value from local storage.
   *
   * If the value is not found in local storage, return the default value of empty array and set it to local storage.
   * If the value is a string, return as single element array and set it to local storage.
   * If the value is an array, return as is.
   *
   * @param key Key of local storage item
   */
  function safeLocalStorageGetArrayItem(key: string): string[] {
    const existingValue = localStorage.getItem(key) ?? setLocalStorageEmptyArray(key);
    try {
      return JSON.parse(existingValue);
    } catch (_) {
      console.error('Failed to parse localStorage value', key, existingValue);
      localStorage.setItem(key, JSON.stringify([existingValue]));
      return [existingValue];
    }
  }

  React.useEffect(
    () => {
      (async function () {
        const inputParamValues = Object.fromEntries(
          dashboardInputParams.map((inputParam) => {
            return [inputParam.key, safeLocalStorageGetArrayItem(`quicksight.${inputParam.key}`)];
          }),
        );

        setInputParamValues(inputParamValues);

        const currentDashboardParameters = Object.fromEntries(
          AllInputParams.map((inputParam) => {
            return [
              inputParam.key,
              getEffectiveInputParamValue(safeLocalStorageGetArrayItem(`quicksight.${inputParam.key}`)),
            ];
          }),
        );
        setParameters({ ...parameters, ...currentDashboardParameters });
        setSelectedId(props.selectedDashboardOptions.id);
        setEmbedUrl('');
        const { embedUrl } = await reportingApi.getEmbedUrl(
          props.dashboards.map((d) => d.id),
          props.selectedDashboardOptions.id,
        );
        if (embedUrl) {
          setEmbedUrl(embedUrl);
        } else {
          setNoAccess(true);
        }
      })();
    },
    config.multiDashboardSessionsEnabled
      ? [reportingApi]
      : [reportingApi, JSON.stringify(props.selectedDashboardOptions)],
  );

  React.useEffect(() => {
    (async function () {
      const translations = await localizationService.getTranslations(session.locale);
      setL10nParams(translations);
    })();
  }, [localizationService]);

  React.useEffect(() => {
    const handler = (evt: CustomEventInit) => {
      setIssueId(undefined);
      if (evt.detail.action === 'save') {
        console.log('TODO: Implement refresh of visuals');
      }
    };
    issueRef.current?.addEventListener('on-close', handler);
    return () => issueRef.current?.removeEventListener('on-close', handler);
  });

  React.useEffect(() => {
    const handler = (evt: CustomEventInit) => {
      setActionId(undefined);
      if (evt.detail.action === 'save') {
        console.log('TODO: Implement refresh of visuals');
      }
    };
    actionRef.current?.addEventListener('on-close', handler);
    return () => actionRef.current?.removeEventListener('on-close', handler);
  });

  return embedUrl && l10nParams ? (
    <>
      {shouldShowSoxSetupToast() ? (
        <Toast>
          {`${I18n.t('toast.soxSetUp.content')} `}
          <Link to="/preset/sox">{I18n.t('toast.soxSetUp.action')}</Link>
        </Toast>
      ) : null}
      {dashboardInputParams.map((p) => (
        <div key={p.key} className="input-param">
          {p.renderComponent(inputParamValues[p.key], (newValues: string[]) => applyInputParamValue(p.key, newValues))}
        </div>
      ))}
      <QuicksightEmbeddingContainer
        embedUrl={embedUrl}
        localeCode={I18n.t('qsLocaleCode')}
        onLoad={onSessionLoaded}
        onParametersChanged={onParametersChanged}
        sheetId={props.selectedDashboardOptions.sheetId}
        resetDisabled={dashboardAlias === 'my_activities'}
        parameters={{ ...parameters, ...l10nParams, ...props.selectedDashboardOptions.extraParams }}
      />

      {actionId ? (
        <hb-edit-action-panel
          id="edit-action-panel"
          app-origin={window.location.origin}
          action-id={actionId}
          panel-mode="open"
          ref={actionRef}
        />
      ) : issueId ? (
        <hb-edit-issue-panel
          id="edit-issue-panel"
          app-origin={window.location.origin}
          issue-id={issueId}
          panel-mode="open"
          ref={issueRef}
        />
      ) : null}
    </>
  ) : noAccess ? (
    <NoAccess />
  ) : (
    <PageSpinner />
  );
}
