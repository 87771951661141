import React from 'react';
import { useI18n } from '@paprika/l10n';
import Modal from '@paprika/modal';
import BubblyError from '@acl-services/wasabicons/lib/BubblyError';
import NotificationCard from '@paprika/notification-card';

const ModalHeader = (Modal as any).Header;
const ModalContent = (Modal as any).Content;

export default function MobileAuthoringNotification(props: { onClose: () => void; type: 'card' | 'modal' }) {
  const { onClose, type } = props;
  const I18n = useI18n();

  if (type === 'card') {
    return (
      <NotificationCard className="empty-list" maxWidth="424px">
        <NotificationCard.Header className="title" level={2}>
          {I18n.t('mobileAuthoringModal.title')}
        </NotificationCard.Header>
        <NotificationCard.Body className="mobile-authoring">
          <BubblyError />
          <p dangerouslySetInnerHTML={{ __html: I18n.t('mobileAuthoringModal.content') }} />
        </NotificationCard.Body>
      </NotificationCard>
    );
  }

  return (
    <Modal size={Modal.types.size.SMALL} isOpen={true} onClose={onClose}>
      <ModalHeader>{I18n.t('mobileAuthoringModal.title')}</ModalHeader>
      <ModalContent className="mobile-authoring">
        <BubblyError />
        <p dangerouslySetInnerHTML={{ __html: I18n.t('mobileAuthoringModal.content') }} />
      </ModalContent>
    </Modal>
  );
}
