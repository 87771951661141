import config from 'config';
import { Dashboard } from 'services/ReportingApiService';

let unlisten: any;

export interface DashboardOptions {
  id: string;
  alias: string;
  sheetId: string;
  extraParams: Record<string, string>;
}

/**
 * DashboardNavigator incapsulates logic for remediating history navigation issues
 * caused by QuickSight iFrame history records (not managed by react-router) that are created on `navigateToDashboard` calls.
 *
 * Current solution is to:
 * 1. Mark dashboard navigation states with `navigateToDashboard: true`
 * 2. Catch history size discrepancy on QS dashboard reloads - these discrepencies mean that iFrame history record was created.
 * To regain the control over history we add additional main window history record.
 * 3. When user click browser "Back" button, we check for `navigateToDashboard` flag and do additional back steps to move
 * to consistent state.
 */
export const DashboardNavigator = {
  navigate(history: any, alias: string, sheetIndex = 0, extraParams: { [name: string]: string } = {}): void {
    let params: string[] = [];
    if (sheetIndex > 0) {
      params.push(`tab=${sheetIndex}`);
    }
    if (Object.keys(extraParams).length) {
      params.push(`params=${encodeURIComponent(JSON.stringify(extraParams))}`);
    }
    history.push(`/preset/${alias}` + (params.length ? `?${params.join('&')}` : ''), { navigateToDashboard: true });
  },

  fixHistoryIfNeeded(history: any): void {
    if (config.multiDashboardSessionsEnabled && window.history.length > history.length) {
      if (unlisten) {
        unlisten();
      }
      unlisten = history.listen((location, action) => {
        if (action === 'POP' && location.state?.navigateToDashboard) {
          window.history.go(-2);
        }
      });
      history.push(window.location, { navigateToDashboard: true });
    }
  },

  extractDashboardOptions(dashboard: Dashboard, query: URLSearchParams): DashboardOptions {
    const sheetIndex = +(query.get('tab') || '0');
    const extraParams = JSON.parse(decodeURIComponent(query.get('params') || '{}'));
    return {
      id: dashboard.id,
      alias: dashboard.alias,
      sheetId: dashboard.sheets[sheetIndex] || '',
      extraParams,
    };
  },
};
