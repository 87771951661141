import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import MainPageHeader from 'components/main/MainPageHeader';
import ActivityCenter from 'components/main/ActivityCenter';
import PageSpinner from 'components/common/PageSpinner';
import SidePanel from 'components/SidePanel';
import { DashboardNavigator } from 'services/DashboardNavigator';
import AuthoringTakeover from 'components/authoring/AuthoringTakeover';

import './MainPage.scss';
import useDashboards from 'hooks/useDashboards';

export default function MainPage() {
  const { alias } = useParams<{ alias: string }>();
  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [authoringAnalysisId, setAuthoringAnalysisId] = React.useState<string | null>(null);

  const { isLoading, data: dashboards, getSelectedDashboard, refreshDashboards } = useDashboards();

  function handleClickEdit(analysisId: string) {
    setAuthoringAnalysisId(analysisId);
  }

  function handleCloseAuthoringSession() {
    refreshDashboards();
    setAuthoringAnalysisId(null);
  }

  const selectedDashboard = getSelectedDashboard(alias);

  if (!alias && !isLoading && dashboards?.length) {
    return <Redirect to={`preset/${dashboards[0].alias}`} />;
  }

  return (
    <div className="main">
      <SidePanel dashboards={dashboards ?? []} selectedDashboard={selectedDashboard} isLoading={isLoading} />
      <div className="main-content">
        <MainPageHeader key={alias} dashboard={selectedDashboard} onClickEdit={handleClickEdit} />
        {authoringAnalysisId ? (
          <AuthoringTakeover analysisId={authoringAnalysisId} onClose={handleCloseAuthoringSession} />
        ) : null}
        {dashboards && selectedDashboard ? (
          <ActivityCenter
            dashboards={dashboards}
            selectedDashboardOptions={DashboardNavigator.extractDashboardOptions(selectedDashboard, query)}
          />
        ) : (
          <PageSpinner />
        )}
      </div>
    </div>
  );
}
