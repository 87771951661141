import En from './en.yml';
import De from './de.yml';
import Es from './es.yml';
import Fr from './fr.yml';
import Ja from './ja.yml';
import Pt from './pt.yml';
import Zh from './zh.yml';

const locales = {
  en: En.en,
  de: De.de,
  es: Es.es,
  fr: Fr.fr,
  ja: Ja.ja,
  pt: Pt.pt,
  zh: Zh.zh,
};

export default locales;
