const protocol = window.location.protocol;
const [orgDomain, subdomain, domain, tld] = window.location.hostname.split('.');
const regionSuffix = subdomain?.split('-')?.reverse()[0] ?? '';
const region = regionSuffix.length === 2 && regionSuffix !== 'us' ? `-${regionSuffix}` : '';
const spaName = process.env.REACT_APP_SPA_NAME || subdomain?.substring(0, subdomain.length - region.length);
const rootDomain = `${domain}.${tld}`;

const config = {
  manzamaPocEnabled: domain == 'highbond-s1' || domain == 'highbond-s3', // for testing purposes only
  launchpadUrl: `${protocol}//accounts.${rootDomain}`,
  loginUrl: `${protocol}//accounts.${rootDomain}/login`,
  tokenRefreshUrl: `${protocol}//accounts.${rootDomain}/api/token/refresh`,
  reportingApiUrl: `${protocol}//${spaName}-api${region}.${rootDomain}`,
  launchpadApiUrl: `${protocol}//apis${region}.${rootDomain}/accounts/api`,
  apisUrl: `${protocol}//apis${region}.${rootDomain}`,
  projectsAppUrl: `${protocol}//${orgDomain}.projects${region}.${rootDomain}`,
  pendoMonitorScript: `${protocol}//monitor${region}.${rootDomain}/v1/monitor.js`,
  microFrontends: {
    'hb-edit-issue-panel': {
      url: `${protocol}//web-components${region}.${rootDomain}/edit-issue-panel/index.js`,
    },
    'hb-edit-action-panel': {
      url: `${protocol}//web-components${region}.${rootDomain}/edit-action-panel/index.js`,
    },
  },
  /* enabling multi-dashboard sessions is blocked by:
   * - QS embedding SDK error when passing a lot of parameters to `setParameters` or `navigateToDashboard` methods
   * - DasboardNavigator implementation bug mentioned here: https://aclgrc.atlassian.net/browse/RNG-342?focusedCommentId=341014
   */
  multiDashboardSessionsEnabled: false,
};

export default config;
