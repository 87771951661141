export class ActionTrigger {
  private done = false;
  public actionName: string | undefined;
  public actionParams: any[] = [];
  private indexedParams: { [index: string]: any } = {};

  constructor(private actions: { [name: string]: (...args: any[]) => void }) {}

  setActionName(actionName: string): void {
    if (this.done) return;
    this.actionName = actionName;
    this.tryTrigger();
  }

  setParam(index: number, value: any): void {
    if (this.done) return;
    this.indexedParams[index] = value;
    this.actionParams = Object.entries(this.indexedParams)
      .map(([index, value]) => [parseInt(index), value] as const)
      .sort(([a], [b]) => a - b)
      .filter(([index], i) => index === i)
      .map(([, value]) => value);
    this.tryTrigger();
  }

  private tryTrigger() {
    if (this.actionName) {
      const action = this.actions[this.actionName];
      if (action && this.actionParams.length >= action.length) {
        this.done = true;
        action(...this.actionParams);
      }
    }
  }
}
