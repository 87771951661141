"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Do not edit this file, it is automatically generated by @paprika/build-translations
var locales = {
    "de": {
        "translation": {
            "global_navigation": {
                "app_switcher_title": "Diligent HighBond",
                "navigation_items": {
                    "logo_org_name": "Gehe zu Launchpad",
                    "logo": "Zurück zum Launchpad",
                    "app_switcher": "Apps wechseln",
                    "announcer": "Ankündigungen",
                    "helper": "Hilfe und Support",
                    "user": "Angemeldet: {{username}}",
                    "user_with_customer": "{{username}} {{customerName}}",
                    "sign_in": "Anmelden",
                    "keyboard_shortcut_panel_tooltip": "Tastenkombinationen [Strg + /]"
                },
                "apps": {
                    "academy": "Akademie",
                    "community": "Community",
                    "projects": "Projekte",
                    "results": "Ergebnisse",
                    "storyboards": "Storyboards",
                    "mission_control": "Kontrollzentrum",
                    "risks": "Strategie",
                    "reports": "Berichte",
                    "launchpad": "Launchpad",
                    "robots": "Robots",
                    "frameworks": "Frameworks",
                    "compliance_maps": "Compliance-Maps",
                    "assurance_plans": "Sicherungspläne",
                    "project_scheduler": "Ablaufplaner",
                    "issue_tracker": "Problemverfolgung",
                    "task_tracker": "Aufgabenverfolgung",
                    "project_timesheets": "Stundennachweise",
                    "dashboard": "{{module}}-Dashboard",
                    "master_library": "Konfiguration"
                },
                "external": {
                    "diligent": "Diligent"
                },
                "app_sections": {
                    "setup": "Einstellungen",
                    "workspace": "Arbeitsbereich",
                    "automation": "Automatisierung"
                },
                "user": {
                    "my_profile": "Mein Profil",
                    "log_out": "Abmelden"
                },
                "helper": {
                    "help_docs": "Hilfedokumente",
                    "support": "Support"
                },
                "skip_button": "Bis Hauptinhalt überspringen",
                "logo": "Logo",
                "other": "Andere",
                "section": "Abschnitt",
                "keyboard_section_headers": {
                    "basic": "Grundlegend",
                    "presentation": "Präsentation",
                    "text_editor": "Texteditor",
                    "script_editor": "Robots-Skript-Editor",
                    "scheduler": "Ablaufplaner"
                },
                "keyboard_commands": {
                    "basic": {
                        "header": "Tastenkombinationen",
                        "close_dialogs": "Dialogfenster schließen"
                    },
                    "presentation": {
                        "next_slide": "Nächste Folie",
                        "prev_slide": "Vorherige Folie",
                        "first_slide": "Erste Folie",
                        "last_slide": "Letzte Folie",
                        "fullscreen": "Vollbild",
                        "slide_overview": "Übersicht der Folien"
                    },
                    "text_editor": {
                        "bold": "Fett",
                        "italic": "Kursiv",
                        "underline": "Unterstreichen",
                        "select_all": "Alle auswählen",
                        "redo": "Wiederholen",
                        "undo": "Rückgängig"
                    },
                    "scheduler": {
                        "highlight_row": "Zeile markieren",
                        "open_sidepanel": "Seitenbereich öffnen",
                        "expand_row": "Aktuelle Zeile erweitern",
                        "collapse_row": "Aktuelle Zeile reduzieren",
                        "scroll_up": "Nach oben scrollen",
                        "scroll_down": "Nach unten scrollen",
                        "scroll_left": "Nach links scrollen",
                        "scroll_right": "Nach rechts scrollen"
                    },
                    "script_editor": {
                        "run": "Ausgewählte Zelle ausführen",
                        "stop": "Ausführung beenden",
                        "cell_above": "Zelle darüber auswählen",
                        "cell_below": "Zelle darunter auswählen",
                        "first_cell": "Erste Zelle auswählen",
                        "last_cell": "Letzte Zelle auswählen",
                        "add": "Zelle unten hinzufügen",
                        "delete": "Ausgewählte Zelle löschen"
                    },
                    "bubbly_header": "Noch nicht eingegangen!",
                    "bubbly_description": "Wir unterstützen keine speziellen Tastenkombinationen für diese App, aber wenn Sie Vorschläge haben, <a href=\"javascript:void(0);\" data-pendo-anchor=\"request-shortcuts\">lassen Sie es uns bitte wissen</a>."
                },
                "shortcut_keys": {
                    "esc": "Esc",
                    "enter": "Eingabe",
                    "home": "Pos1",
                    "end": "Ende",
                    "space": "Leerzeichen",
                    "shiftKey": "Umschalt",
                    "altKey": "Alt",
                    "optionKey": "Option",
                    "ctrlKey": "Strg"
                },
                "training": {
                    "app_name": "{{appName}} (SCHULUNG)",
                    "app_switcher_product_title": "HIGHBOND (SCHULUNG)"
                }
            }
        }
    }
};
exports.default = locales;
