import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.scss';
import GlobalNav from 'components/GlobalNav';
import L10n from '@paprika/l10n';
import Locales from './locales/index';
import { useSession } from './providers/SessionProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReportingApiProvider from './providers/ReportingApiProvider';
import HighbondApiProvider from './providers/HighbondApiProvider';
import LocalizationProvider from './providers/LocalizationProvider';
import MainPage from 'components/main/MainPage';
import ManagePage from 'components/authoring/ManagePage';
import ManzamaPOC from 'components/main/ManzamaPOC';
import config from 'config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000 * 5, // Refresh every 5 minutes
      retry: 2,
    },
  },
});

export default function App(): JSX.Element {
  const session = useSession();

  return (
    <L10n locale={session.locale} locales={Locales}>
      <ReportingApiProvider>
        <HighbondApiProvider>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider>
              <Router>
                <div className="layout">
                  <GlobalNav />
                  <Switch>
                    <Route exact path="/">
                      <MainPage />
                    </Route>
                    <Route path="/preset/:alias">
                      <MainPage />
                    </Route>
                    {session.features['authoring'] && (
                      <Route exact path="/manage">
                        <ManagePage />
                      </Route>
                    )}
                    {config.manzamaPocEnabled && (
                      <Route exact path="/boards/manzama_poc">
                        <ManzamaPOC />
                      </Route>
                    )}
                  </Switch>
                </div>
              </Router>
            </LocalizationProvider>
          </QueryClientProvider>
        </HighbondApiProvider>
      </ReportingApiProvider>
    </L10n>
  );
}
