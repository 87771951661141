import React from 'react';
import { useSession } from 'providers/SessionProvider';
import { useHighbondApi } from 'providers/HighbondApiProvider';
import { useI18n } from '@paprika/l10n';
import { default as debounce } from 'lodash.debounce';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SoxProjectInput(props: { initalValue?: string[]; callback: (newValues: string[]) => void }) {
  const I18n = useI18n();
  const session = useSession();
  const highbondApi = useHighbondApi();
  const inputLimit = 15;

  const [open, setOpen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [values, setValue] = React.useState<string[]>(props.initalValue ?? []);

  function applyNewValue(newValues: string[]): void {
    setValue(newValues);
    props.callback((newValues ?? []).map((newValue) => newValue.trim()));
  }

  React.useEffect(() => {
    searchProjects(values ?? '');
  }, []);

  const searchProjects = debounce(async (namesFilter: string[]) => {
    setLoading(true);
    const data = await highbondApi.searchProjects(session.orgId, namesFilter);
    setOptions(data);
    setLoading(false);
  }, 200);

  return (
    <Autocomplete
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={values}
      onInputChange={(_, newValue) => searchProjects([newValue])}
      onChange={(_, newValues) => {
        applyNewValue(newValues.map((v) => v.name ?? v));
      }}
      getOptionDisabled={(_) => values.length >= inputLimit}
      getOptionLabel={(option) => option.name ?? option}
      isOptionEqualToValue={(option, value) => option.name === value}
      options={options}
      loading={loading}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={I18n.t('soxProjectName.label')}
          placeholder={I18n.t('soxProjectName.placeholder')}
          variant="standard"
          sx={{ maxWidth: '600px' }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
        />
      )}
    />
  );
}
