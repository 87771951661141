"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.shortcutKeys = exports.commandLabels = exports.winShortcutKeys = exports.macShortcutKeys = exports.DELIMITERS = exports.sectionOrder = exports.SECTIONS = exports.isMac = void 0;
exports.isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
exports.SECTIONS = {
    BASIC: 'basic',
    BUBBLY: 'bubbly',
    DEFAULT: 'default',
    PRESENTATION: 'presentation',
    TEXT_EDITOR: 'text_editor',
    SCRIPT_EDITOR: 'script_editor',
    SCHEDULER: 'scheduler',
};
var BASIC = exports.SECTIONS.BASIC, BUBBLY = exports.SECTIONS.BUBBLY, PRESENTATION = exports.SECTIONS.PRESENTATION, TEXT_EDITOR = exports.SECTIONS.TEXT_EDITOR, SCRIPT_EDITOR = exports.SECTIONS.SCRIPT_EDITOR, SCHEDULER = exports.SECTIONS.SCHEDULER;
exports.sectionOrder = {
    default: [BASIC, BUBBLY],
    projects: [BASIC, TEXT_EDITOR, SCHEDULER],
    results: [BASIC, PRESENTATION, TEXT_EDITOR],
    robotics: [BASIC, SCRIPT_EDITOR],
    storyboards: [BASIC, PRESENTATION, TEXT_EDITOR],
};
exports.DELIMITERS = {
    COMMA: 'COMMA',
    PLUS: 'PLUS',
    BACKSLASH: 'BACKSLASH',
};
exports.macShortcutKeys = (_a = {},
    _a[BASIC] = {
        KEYBOARD_SHORTCUT: ['metaKey', exports.DELIMITERS.PLUS, '/'],
        DISMISS: ['esc'],
    },
    _a[PRESENTATION] = {
        NEXT_SLIDE: ['space', exports.DELIMITERS.COMMA, 'arrowright'],
        PREV_SLIDE: ['arrowleft'],
        FIRST_SLIDE: ['home'],
        LAST_SLIDE: ['end'],
        FULLSCREEN: ['f'],
        SLIDE_OVERVIEW: ['o'],
    },
    _a[TEXT_EDITOR] = {
        BOLD: ['metaKey', exports.DELIMITERS.PLUS, 'b'],
        ITALIC: ['metaKey', exports.DELIMITERS.PLUS, 'i'],
        UNDERLINE: ['metaKey', exports.DELIMITERS.PLUS, 'u'],
        SELECT_ALL: ['metaKey', exports.DELIMITERS.PLUS, 'a'],
        REDO: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'z'],
        UNDO: ['metaKey', exports.DELIMITERS.PLUS, 'z'],
    },
    _a[SCHEDULER] = {
        HIGHLIGHT_ROW: ['space'],
        OPEN_SIDEPANEL: ['enter'],
        EXPAND_ROW: ['shiftKey', exports.DELIMITERS.PLUS, 'arrowright'],
        COLLAPSE_ROW: ['shiftKey', exports.DELIMITERS.PLUS, 'arrowleft'],
        SCROLL_UP: ['optionKey', exports.DELIMITERS.PLUS, 'arrowup'],
        SCROLL_DOWN: ['optionKey', exports.DELIMITERS.PLUS, 'arrowdown'],
        SCROLL_LEFT: ['optionKey', exports.DELIMITERS.PLUS, 'arrowleft'],
        SCROLL_RIGHT: ['optionKey', exports.DELIMITERS.PLUS, 'arrowright'],
    },
    _a[SCRIPT_EDITOR] = {
        RUN: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'enter'],
        STOP: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'i'],
        CELL_ABOVE: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'arrowup', exports.DELIMITERS.BACKSLASH, 'k'],
        CELL_BELOW: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'arrowdown', exports.DELIMITERS.BACKSLASH, 'j'],
        FIRST_CELL: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'home', exports.DELIMITERS.BACKSLASH, 'h'],
        LAST_CELL: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'end', exports.DELIMITERS.BACKSLASH, 'l'],
        ADD_CELL: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'b'],
        DELETE_CELL: ['metaKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'd'],
    },
    _a);
exports.winShortcutKeys = (_b = {},
    _b[BASIC] = {
        KEYBOARD_SHORTCUT: ['ctrlKey', exports.DELIMITERS.PLUS, '/'],
        DISMISS: ['esc'],
    },
    _b[PRESENTATION] = {
        NEXT_SLIDE: ['space', exports.DELIMITERS.COMMA, 'arrowright'],
        PREV_SLIDE: ['arrowleft'],
        FIRST_SLIDE: ['home'],
        LAST_SLIDE: ['end'],
        FULLSCREEN: ['f'],
        SLIDE_OVERVIEW: ['o'],
    },
    _b[TEXT_EDITOR] = {
        BOLD: ['ctrlKey', exports.DELIMITERS.PLUS, 'b'],
        ITALIC: ['ctrlKey', exports.DELIMITERS.PLUS, 'i'],
        UNDERLINE: ['ctrlKey', exports.DELIMITERS.PLUS, 'u'],
        SELECT_ALL: ['ctrlKey', exports.DELIMITERS.PLUS, 'a'],
        REDO: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'z'],
        UNDO: ['ctrlKey', exports.DELIMITERS.PLUS, 'z'],
    },
    _b[SCHEDULER] = {
        HIGHLIGHT_ROW: ['space'],
        OPEN_SIDEPANEL: ['enter'],
        EXPAND_ROW: ['shiftKey', exports.DELIMITERS.PLUS, 'arrowright'],
        COLLAPSE_ROW: ['shiftKey', exports.DELIMITERS.PLUS, 'arrowleft'],
        SCROLL_UP: ['altKey', exports.DELIMITERS.PLUS, 'arrowup'],
        SCROLL_DOWN: ['altKey', exports.DELIMITERS.PLUS, 'arrowdown'],
        SCROLL_LEFT: ['altKey', exports.DELIMITERS.PLUS, 'arrowleft'],
        SCROLL_RIGHT: ['altKey', exports.DELIMITERS.PLUS, 'arrowright'],
    },
    _b[SCRIPT_EDITOR] = {
        RUN: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'enter'],
        STOP: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'i'],
        CELL_ABOVE: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'arrowup', exports.DELIMITERS.BACKSLASH, 'k'],
        CELL_BELOW: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'arrowdown', exports.DELIMITERS.BACKSLASH, 'j'],
        FIRST_CELL: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'home', exports.DELIMITERS.BACKSLASH, 'h'],
        LAST_CELL: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'end', exports.DELIMITERS.BACKSLASH, 'l'],
        ADD_CELL: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'b'],
        DELETE_CELL: ['ctrlKey', exports.DELIMITERS.PLUS, 'shiftKey', exports.DELIMITERS.PLUS, 'd'],
    },
    _b);
exports.commandLabels = {
    KEYBOARD_SHORTCUT: 'global_navigation.keyboard_commands.basic.header',
    DISMISS: 'global_navigation.keyboard_commands.basic.close_dialogs',
    NEXT_SLIDE: 'global_navigation.keyboard_commands.presentation.next_slide',
    PREV_SLIDE: 'global_navigation.keyboard_commands.presentation.prev_slide',
    FIRST_SLIDE: 'global_navigation.keyboard_commands.presentation.first_slide',
    LAST_SLIDE: 'global_navigation.keyboard_commands.presentation.last_slide',
    FULLSCREEN: 'global_navigation.keyboard_commands.presentation.fullscreen',
    SLIDE_OVERVIEW: 'global_navigation.keyboard_commands.presentation.slide_overview',
    BOLD: 'global_navigation.keyboard_commands.text_editor.bold',
    ITALIC: 'global_navigation.keyboard_commands.text_editor.italic',
    UNDERLINE: 'global_navigation.keyboard_commands.text_editor.underline',
    SELECT_ALL: 'global_navigation.keyboard_commands.text_editor.select_all',
    REDO: 'global_navigation.keyboard_commands.text_editor.redo',
    UNDO: 'global_navigation.keyboard_commands.text_editor.undo',
    HIGHLIGHT_ROW: 'global_navigation.keyboard_commands.scheduler.highlight_row',
    OPEN_SIDEPANEL: 'global_navigation.keyboard_commands.scheduler.open_sidepanel',
    EXPAND_ROW: 'global_navigation.keyboard_commands.scheduler.expand_row',
    COLLAPSE_ROW: 'global_navigation.keyboard_commands.scheduler.collapse_row',
    SCROLL_UP: 'global_navigation.keyboard_commands.scheduler.scroll_up',
    SCROLL_DOWN: 'global_navigation.keyboard_commands.scheduler.scroll_down',
    SCROLL_LEFT: 'global_navigation.keyboard_commands.scheduler.scroll_left',
    SCROLL_RIGHT: 'global_navigation.keyboard_commands.scheduler.scroll_right',
    RUN: 'global_navigation.keyboard_commands.script_editor.run',
    STOP: 'global_navigation.keyboard_commands.script_editor.stop',
    CELL_ABOVE: 'global_navigation.keyboard_commands.script_editor.cell_above',
    CELL_BELOW: 'global_navigation.keyboard_commands.script_editor.cell_below',
    FIRST_CELL: 'global_navigation.keyboard_commands.script_editor.first_cell',
    LAST_CELL: 'global_navigation.keyboard_commands.script_editor.last_cell',
    ADD_CELL: 'global_navigation.keyboard_commands.script_editor.add',
    DELETE_CELL: 'global_navigation.keyboard_commands.script_editor.delete',
};
exports.shortcutKeys = exports.isMac ? exports.macShortcutKeys : exports.winShortcutKeys;
