"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Do not edit this file, it is automatically generated by @paprika/build-translations
var locales = {
    "zh": {
        "translation": {
            "global_navigation": {
                "app_switcher_title": "Diligent HighBond",
                "navigation_items": {
                    "logo_org_name": "转到启动面板",
                    "logo": "返回到启动面板",
                    "app_switcher": "切换应用",
                    "announcer": "公告",
                    "helper": "帮助和支持",
                    "user": "登录：{{username}}",
                    "user_with_customer": "{{username}} {{customerName}}",
                    "sign_in": "登录",
                    "keyboard_shortcut_panel_tooltip": "键盘快捷键 [Ctrl + /]"
                },
                "apps": {
                    "academy": "学校",
                    "community": "社区",
                    "projects": "项目",
                    "results": "结果",
                    "storyboards": "Storyboard",
                    "mission_control": "任务控制",
                    "risks": "战略",
                    "reports": "报告",
                    "launchpad": "启动面板",
                    "robots": "机器人",
                    "frameworks": "框架",
                    "compliance_maps": "合规地图",
                    "assurance_plans": "保障计划",
                    "project_scheduler": "计划器",
                    "issue_tracker": "问题跟踪器",
                    "task_tracker": "任务跟踪器",
                    "project_timesheets": "时间表",
                    "dashboard": "{{module}}仪表盘",
                    "master_library": "配置"
                },
                "external": {
                    "diligent": "Diligent"
                },
                "app_sections": {
                    "setup": "设置",
                    "workspace": "工作空间",
                    "automation": "自动化"
                },
                "user": {
                    "my_profile": "我的个人资料",
                    "log_out": "注销"
                },
                "helper": {
                    "help_docs": "帮助文档",
                    "support": "支持"
                },
                "skip_button": "跳到主要内容",
                "logo": "徽标",
                "other": "其他",
                "section": "部分",
                "keyboard_section_headers": {
                    "basic": "基本",
                    "presentation": "展示",
                    "text_editor": "文本编辑器",
                    "script_editor": "机器人应用程序脚本编辑器",
                    "scheduler": "计划器"
                },
                "keyboard_commands": {
                    "basic": {
                        "header": "键盘快捷键",
                        "close_dialogs": "关闭对话框"
                    },
                    "presentation": {
                        "next_slide": "下一张幻灯片",
                        "prev_slide": "上一张幻灯片",
                        "first_slide": "第一张幻灯片",
                        "last_slide": "最后一张幻灯片",
                        "fullscreen": "全屏",
                        "slide_overview": "幻灯片概述"
                    },
                    "text_editor": {
                        "bold": "粗体",
                        "italic": "斜体",
                        "underline": "下划线",
                        "select_all": "全选",
                        "redo": "重做",
                        "undo": "撤消"
                    },
                    "scheduler": {
                        "highlight_row": "突出显示一行",
                        "open_sidepanel": "打开侧面板",
                        "expand_row": "展开当前行",
                        "collapse_row": "收起当前行",
                        "scroll_up": "向上滚动",
                        "scroll_down": "向下滚动",
                        "scroll_left": "向左滚动",
                        "scroll_right": "向右滚动"
                    },
                    "script_editor": {
                        "run": "运行选定的单元格",
                        "stop": "停止执行",
                        "cell_above": "选择上面的单元格",
                        "cell_below": "选择下面的单元格",
                        "first_cell": "选择第一个单元格",
                        "last_cell": "选择最后一个单元格",
                        "add": "添加下面的单元格",
                        "delete": "删除选定的单元格"
                    },
                    "bubbly_header": "这里还是空的！",
                    "bubbly_description": "我们不支持此应用程序的特定快捷方式，但如果您有任何建议，请<a href=\"javascript:void(0);\" data-pendo-anchor=\"request-shortcuts\">告知我们</a>。"
                },
                "shortcut_keys": {
                    "esc": "退出",
                    "enter": "Enter",
                    "home": "Home",
                    "end": "End",
                    "space": "空格",
                    "shiftKey": "Shift",
                    "altKey": "Alt",
                    "optionKey": "选项",
                    "ctrlKey": "Ctrl"
                },
                "training": {
                    "app_name": "{{appName}} (培训)",
                    "app_switcher_product_title": "HIGHBOND (培训)"
                }
            }
        }
    }
};
exports.default = locales;
