import { client } from 'http_client';
import config from 'config';

export interface Session {
  locale: string;
  orgLocale: string;
  orgId: string;
  hasAuthoringPermissions: boolean;
  subscriptionState: string;
  features: Record<string, any>;
}

export interface Dashboard {
  id: string;
  name: string;
  alias: string;
  sheets: string[];
  custom: boolean;
}

export interface AuthoringDashboard {
  id: string;
  name: string;
  updatedAt: string;
  analysisId?: string;
  status: 'published' | 'unpublished' | 'orphaned';
  canEdit?: boolean;
}
export default class ReportingApiService {
  private async fetchJson(url, extraOptions = {}) {
    const response = await client.fetchWithTokenRefresh(url, {
      credentials: 'include',
      ...extraOptions,
    });
    if (response.ok) {
      return await response.json();
    } else {
      // TODO:
    }
  }
  getSession(): Promise<Session> {
    return this.fetchJson(`${config.reportingApiUrl}/session`);
  }
  getDashboards(): Promise<{ managed: Dashboard[]; custom: Dashboard[] }> {
    return this.fetchJson(`${config.reportingApiUrl}/dashboards`);
  }
  getEmbedUrl(dashboardIds: string[], selectedId: string): Promise<{ embedUrl: string }> {
    return this.fetchJson(
      `${config.reportingApiUrl}/embed_url?ids=${dashboardIds.join(',')}&selected_id=${selectedId}`,
      {
        cache: 'no-store',
      },
    );
  }
  getAuthoringSessionUrl(analysisId?: string): Promise<{ embedUrl?: string | null; error?: string }> {
    return this.fetchJson(
      `${config.reportingApiUrl}/authoring/embed_url` + (analysisId ? `?analysis_id=${analysisId}` : ''),
    );
  }
  getDashboard(id: string): Promise<AuthoringDashboard> {
    return this.fetchJson(`${config.reportingApiUrl}/authoring/dashboards/${id}`);
  }
}
